import {
  MLP_CLOSING_MODULE_EXPERIMENT,
  EMPTY_BLOCK,
} from 'constants/content-types'

import { memo, Fragment } from 'react'
import dynamic from 'next/dynamic'
import renderBlock from 'utils/landing-page'
import { VariationSwitcher } from 'components/variation-container'
import { DEFAULT_LANGUAGE_CODE } from '@typeform/ginger/dist/lib/i18n'
import { renderComponentsWithNewMediaModule } from 'utils/landing-page/render-block'

import { TLandingPageBelowFoldProps, TModuleProps } from './types'

const CtaModule = dynamic(() => import('components/modules/cta-module'))

const LandingPageBelowFold = (props: TLandingPageBelowFoldProps) => {
  const {
    blocks,
    locale,
    path,
    pricingPlans,
    growthPricingPlans,
    discounts,
    closingModule: closingOrVariation,
  } = props

  return (
    <>
      {blocks.map((block, index) => {
        const module = renderBlock(block, locale, index, {
          path,
          pricingPlans,
          discounts,
          growthPricingPlans,
        })

        return <Fragment key={block.id}>{module}</Fragment>
      })}
      {closingOrVariation && (
        // @ts-expect-error VariationSwitcher hasn't been migrated to Typescript yet
        <VariationSwitcher
          {...closingOrVariation}
          experimentContentType={MLP_CLOSING_MODULE_EXPERIMENT}
        >
          {(moduleProps: TModuleProps) => {
            if (moduleProps?.contentType === EMPTY_BLOCK) {
              return null
            }

            return renderComponentsWithNewMediaModule(moduleProps, CtaModule)
          }}
        </VariationSwitcher>
      )}
    </>
  )
}

LandingPageBelowFold.defaultProps = {
  blocks: [],
  locale: DEFAULT_LANGUAGE_CODE,
  path: '',
  pricingPlans: [],
  growthPricingPlans: [],
  discounts: [],
  closingModule: null,
}

export default memo(LandingPageBelowFold)
